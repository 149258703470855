footer {
  background: #5954A4;
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 90px;
  z-index: -1;
}
.menu-popup {
  font-size: small;
  padding-top: 0px;
  padding: 10px;
}
.fileManager {
  position: relative;
  margin-top: 13px;
}
.menu-popup-content {
  background-color: #021731;

  color: white;
  align-items: center;
  position: relative;
}

.menu-item {
  padding: 1px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  top: 20px;
}

.menu-item:hover {
  background-color: #052a56;
}
.menu-item-label {
  flex-grow: 1;
  margin-right: 50px;
  align-self: flex-end;
}

.menu-item-accelerator {
  color: #777;
  padding: 0;
}
.separator {
  background-color: white; /* Change the color to white */
  height: 1px;
  top: -20px;
}
.quit-modal {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.quit-modal-content {
  background-color: #021731;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.quit-modal-message {
  font-size: 18px;
  margin-bottom: 20px;
  color: white;
}

.quit-modal-buttons {
  display: flex;
  justify-content: center;
}

.quit-modal-confirm,
.quit-modal-cancel {
  padding: 10px 20px;
  margin-left: 10px;
}

.quit-modal-confirm {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.quit-modal-cancel {
  background-color: #ccc;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

*:focus {
  outline: none;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.drag-body {
  -webkit-app-region: drag;
  -webkit-user-drag: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
  position: relative;
}

.icon-wrap {
  right: -20px;
  width: 62px;
  height: 42px;
  position: absolute;
  background: #021731;
  bottom: -75px;
  border-bottom-left-radius: 22px;
  border-top-left-radius: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -2px 0 3px 0 rgba(0, 0, 0, 0.1), 0 0 12px 4px rgba(0, 0, 0, 0);
  transition: all 0.3s 1s linear;
  opacity: 0;
  z-index: 999;
}

.icon-wrap.on {
  opacity: 1;
  transition: all 0.5s;
}

.icon-wrap:hover {
  transition: all 0.2s linear;
}

.icon-wrap img:hover {
  transform: rotate(90deg);
}

.icon-wrap img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  opacity: 0;
}

.icon-wrap.on img {
  opacity: 1;
  transition: opacity 3s, transform 0.3s ease-in-out;
}

.icon-wrap.on:hover img {
  transform: rotate(90deg);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  backdrop-filter: blur(20px);
  background-size: cover;
  min-height: 100vh;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.settings-bar {
  height: 0;
  width: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.9s;
  z-index: 9999;
}

.settings-bar img,
.settings-bar div {
  opacity: 0;
}

.settings-bar .settings-icon {
  opacity: 0;
  transition: opacity 0.5s;
}
.settings-bar.open .settings-icon img,
.settings-bar.open .settings-icon.closed {
  opacity: 1;
  transition: opacity 0.5s;
  
}
.settings-bar .settings-icon {
  visibility: hidden;
}
.settings-bar.open .settings-icon {
  visibility: visible;
}

.settings-bar .settings-icon-a img {
  opacity: 1;
  transition: opacity 0.5s;
}
.settings-bar.open .settings-icon-a img {
  opacity: 0;
  transition: opacity 0.5s;
}
.settings-bar.open img,
.settings-bar.open div {
  opacity: 1;
  transition: opacity 0.5s;
  transition-delay: 0.5s;
}

.settings-bar.open {
  opacity: 1;
  height: 100vh;
  width: 262px;
  right: 0;
  box-shadow: -4px 0 3px 0 rgba(0, 0, 0, 0.1), 0 0 9px 4px rgba(0, 0, 0, 0.06);
  transition: all 1s;
  z-index: 99999;
}

.readonly-mode {
  opacity: 1 !important;
  pointer-events: pointer;
}
.settings-icon {
  position: absolute;
  z-index: 10;
  /* margin-bottom: -15px; */
}
.svg-container{
  margin-right: 10px;
}
.unset-drag {
  -webkit-app-region: unset !important;
}
.opensetting{
  z-index: 99999;
}